import React, { useEffect, useRef, useState } from 'react';

import QuickView from '../../components/products/QuickView';
import Layout from '../../components/global/Layout';
import { useGlobalContext } from '../../providers/GlobalProvider';
import useOnScreen from '../../utils/CommonUtils';
import Products from '../../components/shop/Products';
import { useProductsContext } from '../../providers/ProductsProvider';
import PageTitle from '../../components/global/PageTitle';
import OrderingToolbar from '../../components/shop/OrderingToolbar';
import styled from 'styled-components';
import { Pagination } from 'react-bootstrap';
import { DefaultCategoryId } from '../../defs';
// import Ordering from '../../components/shop/Ordering';


const HomeLanding = () => {
    const { showQuickView, quickViewData, handelQuickViewClose, layout } = useGlobalContext()

    const [ordering, setOrdering] = useState(1);
    const [title, setTitle] = useState('');
    const [page, setPage] = useState(1);

    const {
        sex,
        getTotalPagesCount,
        isHasPagination,
        categoryProducts,
        getCategoryProducts,
        getCategoryProductsWithAppend,
        // getCategoryNameById,
        currentCatergoryData,
        setCurrentCatergoryData
    } = useProductsContext()

    const loadNewDivRef = useRef()
    const isOnScreen = useOnScreen(loadNewDivRef)

    const onPaginationValueChange = (value) => {
        setPage(value)
        getCategoryProducts(DefaultCategoryId, value - 1, parseInt(sex.value))
        window.scrollTo(0, 0);
    }

    const HandleOrderingStatus = (event, data) => {
        event.preventDefault();
        setOrdering(data);
    };


    useEffect(() => {
        setPage(1);
        getCategoryProducts(DefaultCategoryId, 0, parseInt(sex.value))
        // eslint-disable-next-line
    }, [sex])

    useEffect(() => {
        if (categoryProducts.length > 0 && isOnScreen) {
            const totalPages = getTotalPagesCount();

            if (page + 1 <= totalPages) {
                setPage(page + 1)
            }
        }
        // eslint-disable-next-line
    }, [isOnScreen])

    useEffect(() => {
        if (categoryProducts.length > 0 && isOnScreen) {
            getCategoryProductsWithAppend(DefaultCategoryId, page - 1, parseInt(sex.value))
        }
        // eslint-disable-next-line
    }, [page])

    useEffect(() => {
        setPage(1);
        getCategoryProducts(DefaultCategoryId, 0, parseInt(sex.value))

        // const catName = getCategoryNameById(DefaultCategoryId)
        setTitle("")
        setCurrentCatergoryData(layout.categories.categories_data.find(i => i.id === DefaultCategoryId))

        // eslint-disable-next-line
    }, [])




    return (
        <Layout>
            {showQuickView
                ? <QuickView
                    data={quickViewData}
                    onQuickViewCloseClick={handelQuickViewClose}
                />
                : ''
            }

            {
                currentCatergoryData === null ? <></> : <PageTitle name={title} backgroundImg="/assets/images/page-title/clothes-cover.jpg" />
            }

            {/* start shop-section */}
            <section className="shop-section shop-style-2 section-padding">
                <div className="container-1410">
                    <div className="row">
                        <div className="col col-xs-12">
                            <div className="shop-area clearfix">
                                <div className="woocommerce-content-wrap">
                                    <div className="woocommerce-content-inner">
                                        <div className="woocommerce-toolbar-top">
                                            <p className="woocommerce-result-count">
                                                {/* Showing all {products.length} results */}
                                                {layout.category.show_result_label.replace('{0}', categoryProducts.length)}
                                            </p>

                                            {/* <Ordering /> */}

                                            <OrderingToolbar
                                                HandleOrderingStatus={HandleOrderingStatus}
                                                ordering={ordering}
                                            />


                                        </div>
                                        <Products
                                            products={categoryProducts}
                                            ordering={ordering}
                                        />

                                    </div>

                                    <div ref={loadNewDivRef} />
                                    <Paginatorwrapper>
                                        {
                                            isHasPagination() ? (
                                                <Pagination page={page} count={getTotalPagesCount()} onChange={onPaginationValueChange} />
                                            ) : (<></>)
                                        }

                                    </Paginatorwrapper>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end container */}
            </section>
            {/* end shop-section */}

        </Layout >
    )
}

export default HomeLanding


const Paginatorwrapper = styled.div`
    /* display: flex; */
    display: none;
    justify-content: center;
`