import React, { createContext, useContext, useEffect, useState } from 'react';
import { DefaultRercordsCountPerCategoryCount } from '../defs';
import { useApiContext } from './ApiProvider';
import { useGlobalContext } from './GlobalProvider';
import { useStatsContext } from './StatsProvider';

const ProductsContext = createContext()


const ProductsProvider = ({ children }) => {
    const [recordsPerPageCount, setRecordsPerPageCount] = useState(DefaultRercordsCountPerCategoryCount)
    const [totalRecordsCount, setTotalRecordsCount] = useState(0)
    const [currentProduct, setCurrentProduct] = useState(null)
    const [products, setProducts] = useState([])
    const [categoryProducts, setCategoryProducts] = useState([])
    const [currentCatergoryData, setCurrentCatergoryData] = useState(null)

    const { getProductsRequest, getProductRequest } = useApiContext()
    const { layout } = useGlobalContext()

    const sexTypes = layout.common.sex_options;
    const [sex, setSex] = useState(sexTypes[0])

    const { callCustomEvent } = useStatsContext()

    const errorCallback = (error) => {
        console.log(error)
    }

    const getCategoryNameById = (id) => {
        const cat = layout.categories.categories_data.find((item) => item.id === id);
        return cat === undefined ? "" : cat.name;
    }

    const isHasPagination = () => {
        return (totalRecordsCount > recordsPerPageCount)
    }

    const getTotalPagesCount = () => {
        return Math.ceil(totalRecordsCount / recordsPerPageCount)
    }


    const getCategoryProducts = (categoryId, pageIndex = 0, sexId = 0) => {
        setCategoryProducts([])
        getProductsRequest({
            categoryId: categoryId,
            count: recordsPerPageCount,
            orderBy: 'stars desc',
            sex: sexId,
            pageIndex: pageIndex
        }, (data) => {
            setTotalRecordsCount(data.totalCount)
            setCategoryProducts([...data.products]);
        }, errorCallback)
    }

    const getCategoryProductsWithAppend = (categoryId, pageIndex = 0, sexId = 0) => {
        getProductsRequest({
            categoryId: categoryId,
            count: recordsPerPageCount,
            orderBy: 'stars desc',
            sex: sexId,
            pageIndex: pageIndex
        }, (data) => {
            setTotalRecordsCount(data.totalCount)
            const newArr = [...categoryProducts, ...data.products]
            setCategoryProducts(newArr);
        }, errorCallback)
    }

    const getProductById = (id, getProductComplete, errorHandler) => {
        setCurrentProduct(null)
        getProductRequest({
            id: id
        }, (data) => {
            setCurrentProduct(data)
            getProductComplete(data)
        }, errorHandler)
    }

    useEffect(() => {
        callCustomEvent()

        //TODO to get recent products or bestsellers
        // getProductsRequest({
        //     categoryId: 0,
        //     count: recordsPerPageCount,
        //     orderBy: 'stars desc',
        //     pageIndex: 0
        // }, (data) => {
        //     setProducts(data.products);
        // }, errorCallback)

        setProducts([])

        // eslint-disable-next-line
    }, []);



    const contextValue = {
        products,
        categoryProducts,
        currentProduct,
        currentCatergoryData,

        sexTypes,
        sex,
        setSex,

        setRecordsPerPageCount,

        getTotalPagesCount,
        isHasPagination,
        getProductById,
        getCategoryProducts,
        getCategoryProductsWithAppend,
        getCategoryNameById,
        setCurrentCatergoryData
    }

    return (
        <ProductsContext.Provider value={contextValue}>
            {children}
        </ProductsContext.Provider>
    );
};

export const useProductsContext = () => useContext(ProductsContext)

export default ProductsProvider;
