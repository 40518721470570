export const PUBLIC_CDN_URL = "https://cdn.gunesh.uz/static/images/"

export const DefaultRercordsCountPerCategoryCount = 40;
export const DefaultCategoryId = 9;


export const Sizes = [
    { value: 0, label: "S" },
    { value: 1, label: "M" },
    { value: 2, label: "L" },
    { value: 3, label: "XL" },
] 